// 2,"非农" 3,"EIA" 6,"CPI" 7,"Fed Interest Rate Decision" 8,"Retail Sales"
type ItemForm = {
  title: string;
  content: string;
}
type MainForm = {
  pageTitle: string;
  mainTitle: string;
  dialogTitle: string;
  texts: ItemForm[]
}

const i18nByType = [] as MainForm[]
i18nByType[2] = {
  pageTitle: 'bigevents_6',
  mainTitle: 'bigevents_7',
  dialogTitle: 'notification_62',
  texts: [{
    title: 'bigevents_8',
    content: 'bigevents_9',
  }, {
    title: 'bigevents_10',
    content: 'bigevents_11',
  }, {
    title: 'bigevents_12',
    content: 'bigevents_13',
  }],
}

i18nByType[3] = {
  pageTitle: 'bigevents_14',
  mainTitle: 'bigevents_15',
  dialogTitle: 'notification_63',
  texts: [{
    title: 'bigevents_16',
    content: 'bigevents_17',
  }, {
    title: 'bigevents_18',
    content: 'bigevents_19',
  }],
}

i18nByType[6] = {
  pageTitle: 'bigevents_20',
  mainTitle: 'bigevents_21',
  dialogTitle: 'notification_64',
  texts: [{
    title: 'bigevents_22',
    content: 'bigevents_23',
  }, {
    title: 'bigevents_24',
    content: 'bigevents_25',
  }],
}

i18nByType[7] = {
  pageTitle: 'bigevents_26',
  mainTitle: 'bigevents_27',
  dialogTitle: 'notification_65',
  texts: [{
    title: 'bigevents_28',
    content: 'bigevents_29',
  }, {
    title: 'bigevents_30',
    content: 'bigevents_31',
  }, {
    title: 'bigevents_32',
    content: 'bigevents_33',
  }],
}

i18nByType[8] = {
  pageTitle: 'bigevents_34',
  mainTitle: 'bigevents_35',
  dialogTitle: 'notification_66',
  texts: [{
    title: 'bigevents_36',
    content: 'bigevents_37',
  }, {
    title: 'bigevents_38',
    content: 'bigevents_39',
  }],
}

export default i18nByType
