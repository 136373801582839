import requestResult from '@/common/request/request'

export type NewsInfo = {
  actual: string;
  affectedCodes: string[];
  forecast: string;
  previous: string;
  time: number;
  bgEventId: number;
  bgDate: string;
  bgTime: string;
}

export type Actual = {
  status: number;
  actual: string;
  bgEventId?: number;
  time: number;
}

export const readBigNewsInfo = requestResult<NewsInfo>('/api/event/bg/event')

export const readBigNewsActual = requestResult<Actual>('/api/event/bg/event/actual')
