
import SymbolLink from '@/provider/SymbolLink.vue'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'
import { readBigNewsInfo, readBigNewsActual } from '@/pages/bigNews/bigNews.api'
import { useRouter } from 'vue-router'
import Holder from '@/provider/Holder.vue'
import { formatDateTime } from '@/common/format'
import i18nByType from '@/pages/bigNews/bigNews'
import Scroller from 'common/providers/Scroller.vue'

const ONE_MINUTE = 60

export default defineComponent({
  name: 'BigNews',
  components: {
    SymbolLink,
    PageWithHeader,
    Pic,
    Holder,
    Scroller,
  },
  props: {
    type: String,
    id: String,
  },
  setup (props) {
    const router = useRouter()
    const info = shallowRef()
    const actual = shallowRef()
    const eventId = String(props.id)
    const eventType = Number(props.type)
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
      'October', 'November', 'December',
    ]
    const loading = shallowRef(false)
    let cdTimer = 0
    let loadingTimer = 0

    readBigNewsInfo({
      eventId: eventId,
    }).then(res => {
      const date = new Date(res.time)
      info.value = res
      info.value.md = months[date.getMonth()] + '  ' + formatDateTime('dd', Number(date))
      info.value.hs = formatDateTime('HH:mm', Number(date))
      readActual()
    })

    const clearTimer = () => {
      clearTimeout(cdTimer)
      clearTimeout(loadingTimer)
    }

    const readActual = () =>
      readBigNewsActual({
        eventId: eventId,
      }).then(res => {
        actual.value = res

        if (res.actual) {
          actual.value.up = parseFloat(res.actual) > parseFloat(info.value.forecast)
          actual.value.down = parseFloat(res.actual) < parseFloat(info.value.forecast)
        }

        if (res.actual) {
          clearTimer()
          loading.value = false
        } else if (res.time > ONE_MINUTE) { // 剩余60s启动刷新
          cdTimer = window.setTimeout(readActual, (res.time - ONE_MINUTE) * 1000)
        } else if (res.time > 0 || !res.actual) { // 存在倒计时或者没有实际值时每6s请求一次
          loading.value = true
          loadingTimer = window.setTimeout(readActual, 1000 * 6)
        }
      })

    const enterInfo = (code: string) => {
      router.push('/symbol/' + code)
    }

    onBeforeUnmount(clearTimer)

    return {
      info,
      actual,
      eventType,
      i18nByType,
      enterInfo,
      loading,
    }
  },
})
